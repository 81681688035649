import Button from '@components/AcoButtons/Button';
import styles from '@components/OnlineEvent/PaymentEvents/PaymentEvent.styles';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import { Box, LinearProgress, TextField, Typography } from '@mui/material';
import { sendGTMEvent } from '@next/third-parties/google';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import useScript from '@providers/hooks/useScript';
import { useIsMobile } from '@providers/Responsive.provider';
import { useSession } from '@providers/user/SessionContext';
import { OnlineEvent } from '@service/model';
import { ResponseResult } from '@service/user.service';
import { GA } from '@shared/ga/ga';
import axios from 'axios';
import { useRouter } from 'next/router';
import React from 'react';


const INITIAL_STATE = {
  cvc: '',
  cardExpirationMonth: '',
  cardExpirationYear: '',
  focus: 'cardNumber',
  cardholderName: '',
  cardNumber: '',
  issuer: '',
};

const errorMessages = {
  // OTHE
  'cc_rejected_other_reason': 'Error general.',
  // CONT
  'pending_contingency': 'Pendiente de pago.',
  //CALL
  'cc_rejected_call_for_authorize': 'Rechazado con validación para autorizar.',
  //FUND
  'cc_rejected_insufficient_amount': 'Importe insuficiente.',
  //SECU
  'cc_rejected_bad_filled_security_code': 'Código de seguridad invalido.',
  //EXPI
  'cc_rejected_bad_filled_date': 'Fecha de vencimiento invalida.',
  //FORM
  'cc_rejected_bad_filled_other': 'Error en el formulario.',

};

interface PaymentFormChatProps {
  packId?: number,
  packPrice?: string,
  packAmount?: number,
  handleClose?: (closeModal: boolean) => void,
  onlineEvent?: OnlineEvent,
  credits?: number,
  updateCredits?: (credits: number) => void,
}


export const MercadoPagoForm: React.FC<PaymentFormChatProps> = (props) => {
  const classes = styles();
  const router = useRouter();
  const isMobile = useIsMobile();
  const { snackbars } = useCustomSnackbar();
  const { user, signOut, refreshToken } = useSession();
  const [state, setState] = React.useState(INITIAL_STATE);
  const [view, setView] = React.useState<boolean>(true);
  const [viewCuotas, setViewCuotas] = React.useState<boolean>(false);
  const [viewProgressBar, setViewProgressBar] = React.useState<boolean>(false);
  const amount = props.packPrice ? props.packPrice : props.onlineEvent.settings.amount;


  // @ts-ignore
  const { MercadoPago } = useScript(
    'https://sdk.mercadopago.com/js/v2',
    'MercadoPago',
  );

  React.useEffect(() => {
    if (!MercadoPago) return;

    const mp = new MercadoPago(process.env.NEXT_PUBLIC_APP_USR-c53e64cc-63a7-47fb-846f-80dad1e36924);

    const cardForm = mp.cardForm({
      amount: amount,
      iframe: true,
      autoMount: true,
      form: {
        id: 'form-checkout',
        cardholderName: {
          id: 'form-checkout__cardholderName',
          placeholder: 'Titular de la tarjeta',
        },
        cardholderEmail: {
          id: 'form-checkout__cardholderEmail',
          placeholder: 'E-mail',
        },
        cardNumber: {
          id: 'form-checkout__cardNumber',
          placeholder: 'Número de la tarjeta',
        },
        expirationDate: {
          id: 'form-checkout__expirationDate',
          placeholder: 'MM/YY',
        },
        securityCode: {
          id: 'form-checkout__securityCode',
          placeholder: 'Código',
        },
        installments: {
          id: 'form-checkout__installments',
          placeholder: 'Cuotas',
        },
        identificationType: {
          id: 'form-checkout__identificationType',
          placeholder: 'Tipo de documento',
        },
        identificationNumber: {
          id: 'form-checkout__identificationNumber',
          placeholder: 'Número de documento',
        },
        issuer: {
          id: 'form-checkout__issuer',
          placeholder: 'Banco emisor',
        },
      },
      callbacks: {
        onFormMounted: (error) => {
          if (error)
            return console.warn(
              'Form Mounted handling error: ',
              error,
            );
        },
        onIssuersReceived: (error, issuers) => {
          if (error)
            return console.warn(
              issuers, 'issuers handling error:  ',
              error,
            );
        },
        onSubmit: (event) => {
          event.preventDefault();

          function buildBodyWith(data, token) {
            return {
              op: 'payment',
              user: user,
              token: token,
              object_id: props.packId ? props.packId : `${props.onlineEvent.id}`,
              issuer_id: Number(data.issuerId),
              payment_method_id: data.paymentMethodId,
              transaction_amount: Number(data.amount),
              installments: Number(data.installments),
              description: props.onlineEvent ? `Evento Online ${props.onlineEvent.id} | ${props.onlineEvent.title}` : `Pago chats | Pack ${props.packId}`,
              payer: JSON.stringify({
                email: data.cardholderEmail,
                identification: {
                  type: data.identificationType,
                  number: data.identificationNumber,
                },
              }),
            };
          }

          const data = cardForm.getCardFormData();
          const tokenPromise = cardForm.createCardToken();

          tokenPromise
            .then(token => {

              refreshToken().then(() => {
                const body = buildBodyWith(data, token.token);
                setView(false);

                const url = props.onlineEvent ? '/api/mercadoPago/eventsPayments' : '/api/mercadoPago/chatsInteractionsPayments';

                return axios.post<ResponseResult>(url, body);
              }).then((res) => {
                if (res.data.success) {
                  GA.event({
                    action: 'success_interactions_payment',
                    category: 'button',
                    label: 'eventPayment',
                    value: 1,
                  });

                  if (props.onlineEvent) {
                    router.push(`/events/${props.onlineEvent.id}`);

                    sendGTMEvent({
                      event: 'purchase', value: { id: props.onlineEvent.id, name: props.onlineEvent.title },
                      category: 'purchase_event', label: 'Pagó capacitacion con MercadoPago',
                    });
                  } else {
                    snackbars.showSuccess('Pago exitoso. Se acreditaron tus interacciones.');
                    props.updateCredits(props.credits + props.packAmount);
                    props.handleClose(false);

                    sendGTMEvent({
                      event: 'purchase', value: { credits: props.packAmount },
                      category: 'purchase_pack', label: 'Pagó pack con MercadoPago',
                    });
                  }
                } else {
                  GA.event({
                    action: `error_interactions_payment_${res.data.error}`,
                    category: 'button',
                    label: 'eventPayment',
                    value: 1,
                  });

                  setView(true);
                  handleErrors(res.data.error);
                }
              });
            }).catch((e) => {
              console.error(e);
            },
          );

        },
        onInstallmentsReceived: (error, installments) => {
          if (error) return console.warn('installments handling error: ', error);
          setViewCuotas(true);
        },
        onFetching: (resource) => {
          // Animate progress bar
          setViewProgressBar(true);

          return () => {
            setViewProgressBar(false);
          };
        },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MercadoPago]);

  const handleInputChange = (e) => {
    setState({
      ...state,
      [e.target.dataset.name || e.target.name]: e.target.value,
    });
  };

  const handleInputFocus = (e) => {
    setState({ ...state, focus: e.target.dataset.name || e.target.name });
  };

  const handleErrors = (error: string) => {
    if (error == 'No user') {
      snackbars.showError('Por favor vuelva a iniciar sesion para poder pagar');

      GA.event({
        action: 'close_user_session',
        category: 'click',
        label: 'closeSession',
        value: 1,
      });

      setTimeout(() => signOut(false), 5000);
    } else if (error == 'No se pudo capturar el pago después de varios intentos') {
      router.reload();
    } else {
      snackbars.showError(errorMessages[error] ?? 'Hubo un error al procesar el pago');
    }
  };

  return (
    <Box width='100% ' display='flex' flexDirection='column' alignItems='center'>
      <form id='form-checkout' className={classes.formPago} style={{ display: view ? 'block' : 'none' }}>

        <Box display='flex' flexDirection={isMobile ? 'column' : 'unset'} margin={isMobile ? '0px' : '30px 0px'}>
          <Box className={classes.boxInputs} width='100%'>
            <CreditCardOutlinedIcon fontSize='large' />
            <div id='form-checkout__cardNumber' className={classes.formCCDate}></div>
          </Box>

          <Box className={classes.boxInputs}>
            <PinOutlinedIcon fontSize='large' />
            <div id='form-checkout__securityCode' className={classes.formCCDate}></div>
          </Box>
        </Box>


        <Box display='flex' flexDirection={isMobile ? 'column' : 'unset'} margin={isMobile ? '0px' : '30px 0px'}>

          <Box className={classes.boxInputs} width='100%'>
            <PersonOutlineOutlinedIcon fontSize='large' />
            <Box className={classes.textInputContainer}>
              <TextField
                type='text'
                name='cardholderName'
                id='form-checkout__cardholderName'
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                margin='normal'
                variant='standard'
                autoComplete='off'
                size='medium'
                inputProps={{
                  className: classes.textfield,
                  form: {
                    autoComplete: 'off',
                  },
                }}
              />
            </Box>
          </Box>

          <Box className={classes.boxInputs}>
            <CalendarTodayOutlinedIcon fontSize='large' />
            <div id='form-checkout__expirationDate' className={classes.formCCDate}></div>
          </Box>
        </Box>


        <Box display='flex' flexDirection={isMobile ? 'column' : 'unset'} margin={isMobile ? '0px' : '30px 0px'}
             alignItems={isMobile ? 'unset' : 'flex-end'}>

          <Box className={classes.boxInputs} width={isMobile ? '100%' : '80%'}>
            <BadgeOutlinedIcon fontSize='large' />
            <Box className={classes.textInputContainer}>
              <TextField
                type='text'
                name='identificationNumber'
                id='form-checkout__identificationNumber'
                margin='normal'
                variant='standard'
                autoComplete='off'
                size='medium'
                inputProps={{
                  className: classes.textfield,
                  form: {
                    autoComplete: 'off',
                  },
                }}
              />
            </Box>
          </Box>

          <div className='form-control-select'>
            <select
              className={classes.select}
              name='identificationType'
              id='form-checkout__identificationType'
            ></select>
          </div>

        </Box>

        <Box display='flex' flexDirection='column' alignItems='flex-end' marginTop={isMobile ? '0px' : '30px'}>
          <Box display='flex' alignItems='baseline' width='100%' justifyContent='flex-end'>
            <Typography variant='subtitle1' style={{ display: viewCuotas ? 'block' : 'none' }}>Cuotas</Typography>
            <div className='form-control' style={{ display: viewCuotas ? 'block' : 'none' }}>
              <select
                style={{ marginLeft: '5px', marginBottom: '20px' }}
                className={classes.select}
                name='installments'
                id='form-checkout__installments'
              ></select>
            </div>
          </Box>


          <Box display='flex' justifyContent={viewProgressBar ? 'space-between' : 'flex-end'} width='100%'
               alignItems='center'>
            {viewProgressBar && <Box sx={{ width: '40%' }}>
              <LinearProgress />
            </Box>}

            <div className='form-control'>
              <Button type='submit' id='form-checkout__submit' variant='contained'>
                Pagar
              </Button>
            </div>
          </Box>
        </Box>

        <Box display='none'>

          <div className='form-control'>
            <select
              disabled
              name='issuer'
              id='form-checkout__issuer'
            ></select>
          </div>

          <div className='form-control'>
            <input
              type='tel'
              name='cardExpirationMonth'
              disabled
              id='form-checkout__cardExpirationMonth'
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
            <input
              type='tel'
              name='cardExpirationYear'
              disabled
              id='form-checkout__cardExpirationYear'
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
          </div>

          <div className='form-control'>
            <input
              type='email'
              name='cardholderEmail'
              id='form-checkout__cardholderEmail'
              disabled
              value={user.email}
              onFocus={handleInputFocus}
            />
          </div>

        </Box>
      </form>

      <Box display={view ? 'none' : 'flex'} flexDirection='column' alignItems='center'>
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
        <Typography variant={isMobile ? 'h4' : 'h3'}>Procesando pago..</Typography>
        <Typography>{props.onlineEvent ? 'Una vez aprobado se ingresará al evento.' : 'Una vez aprobado se acreditarán las interacciones.'}</Typography>
      </Box>
    </Box>
  );
};
