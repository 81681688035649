import Footer from '@components/Footer/Footer';
import Header from '@components/Header/Header';
import { AdBlockModal } from '@components/modal/AdBlock/AdBlockModal';
import { ChatInvitation } from '@components/modal/Chat/ChatInvitation';
import { InvitationToConsult } from '@components/modal/Female/invitation';
import OnlineEventHome from '@components/OnlineEvent/OnlineEventHome';
import { UserGender } from '@components/OnlineEvent/PaymentEvents/PaymentEvent';
import RightColumn from '@components/RightColumn/RightColumn';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import { Container, Box, Button, useScrollTrigger } from '@mui/material';
import EventCarouselProvider from '@providers/onlineEvent/eventCarouselContext';
import { OnDesktop } from '@providers/Responsive.provider';
import { useSession } from '@providers/user/SessionContext';
import { GA } from '@shared/ga/ga';
import { useDetectAdBlock } from 'adblock-detect-react';
import axios from 'axios';
import clsx from 'clsx';
import React, { PropsWithChildren, useEffect } from 'react';
import { useCookies } from 'react-cookie';

import styles from './ACOLayout.styles';

interface ACOLayoutProps {
  clean?: boolean;
  withoutRightColumn?: boolean;
  searchText?: string;
  withoutOnLiveEventRightColumn?: boolean;
}

const ACOLayout: React.FC<PropsWithChildren<ACOLayoutProps>> = ({
                                                                  children,
                                                                  clean,
                                                                  withoutRightColumn,
                                                                  searchText,
                                                                  withoutOnLiveEventRightColumn,
                                                                }) => {
  const classes = styles();

  const scroll = useScrollTrigger({ disableHysteresis: true, threshold: 200 });
  const [cookies, setCookie] = useCookies();
  const adBlockDetected = useDetectAdBlock();
  const [openAdBlockModal, setOpenAdBlockModal] = React.useState(false);
  const { isLogged, loading, user } = useSession();
  const [openModalConsult, setOpenModalConsult] = React.useState(false);
  const [openChatInvitationModal, setOpenChatInvitationModal] = React.useState(false);

  const backToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  function showChatModal(md5Hash) {
    return parseInt(md5Hash, 32) % 100 >= 75;
  }

  useEffect(() => {
    if (user && !openModalConsult && !openChatInvitationModal && !openAdBlockModal) {
      const isFemalePromise = user.gender
        ? Promise.resolve(Number(user.gender) === UserGender.FEMENINO)
        : axios.get(`/api/user/${user.id}/gender`).then((res) => res.data === UserGender.FEMENINO);

      isFemalePromise.then((isFemale) => {
        if (isFemale && !cookies['Agroconsultas::InvitationToConsult']) {
          setOpenModalConsult(true);
          const date = new Date();
          date.setDate(date.getDate() + 1);
          setCookie('Agroconsultas::InvitationToConsult', 1, { expires: date });
        } else if (!isFemale && !cookies['Agroconsultas::InvitationToChat'] && showChatModal(cookies['Agroconsultas::UUID']) && !user?.verified) {
          setOpenChatInvitationModal(true);
          const date = new Date();
          date.setDate(date.getDate() + 7);
          setCookie('Agroconsultas::InvitationToChat', 1, { expires: date });
          GA.event({ action: 'open_invitation_chat_modal', category: 'modal', label: 'openModal', value: 1 });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, openModalConsult, openChatInvitationModal, openAdBlockModal]);

  useEffect(() => {
    if (adBlockDetected && !cookies['Agroconsultas::AdBlock'] && !openModalConsult && !openChatInvitationModal) {
      const date = new Date();
      date.setDate(date.getDate() + 1);
      setCookie('Agroconsultas::AdBlock', 1, { expires: date });
      setOpenAdBlockModal(true);
      GA.event({ action: 'open_adblock_modal', category: 'modal', label: 'openModal', value: 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adBlockDetected, openModalConsult, openChatInvitationModal]);


  useEffect(() => {
    if ((loading || isLogged) && !cookies['Agroconsultas::Notificaciones']) {
      const date = new Date();
      date.setDate(date.getDate() + 1);
      setCookie('Agroconsultas::Notificaciones', 1, { expires: date });
      /*requestForToken(snackbars, true);*/
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const container =
    clean ?
      <Container className={clsx(classes.main, classes.cleanContainer)}>
        {children}
      </Container>
      :
      <Container maxWidth='lg' className={classes.main} id='layoutBody'>
        <Box className={classes.principalBox}>
          {children}
        </Box>
        {!withoutRightColumn && (
          <OnDesktop>
            <Box className={classes.rightBox}>
              <EventCarouselProvider>
                {!withoutOnLiveEventRightColumn &&
                  <OnlineEventHome />}
              </EventCarouselProvider>

              <RightColumn />
              <Box mb={2} />
              <Footer />
              {scroll &&
                <Button onClick={backToTop} className={classes.toTopButton}>
                  <ArrowUpwardOutlinedIcon />
                </Button>}
            </Box>
          </OnDesktop>
        )}
      </Container>;

  return (
    <div className={classes.body}>
      <Header searchText={searchText} />
      {container}
      {openModalConsult &&
        <InvitationToConsult modalProps={{ open: openModalConsult, onClose: () => setOpenModalConsult(false) }} />}
      {openAdBlockModal && <AdBlockModal open={openAdBlockModal} onClose={() => setOpenAdBlockModal(false)} />}

      {openChatInvitationModal &&
        <ChatInvitation open={openChatInvitationModal} onClose={() => setOpenChatInvitationModal(false)} />}

    </div>
  )
    ;
};

export default ACOLayout;
