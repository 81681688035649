import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { useSession } from '@providers/user/SessionContext';
import { ResponseResult } from '@service/user.service';
import axios from 'axios';
import { useState } from 'react';
import { useCookies } from 'react-cookie';

export const useChatEnabled = () => {

  const [cookies, setCookie] = useCookies();
  const [enableChat, setEnableChat] = useState(false);
  const { snackbars } = useCustomSnackbar();
  const { user } = useSession();

  const checkEnableChat = (showSnack) => {
    if (user) {
      if (cookies['Agroconsultas::ChatEnabled']) {
        setEnableChat(true);
      } else if (!cookies['Agroconsultas::ConsultToChatEnabled']) {
        axios
          .get<ResponseResult>('/api/user/chatEnabled')
          .then((res) => {
            if (res.data.success) {
              const date = new Date();
              date.setDate(date.getDate() + 365);
              setCookie('Agroconsultas::ChatEnabled', 1, { expires: date });
              setEnableChat(true);
            } else {
              showSnack && snackbars.showError(res.data.error);
            }

            const date = new Date();
            date.setDate(date.getDate() + 1);
            setCookie('Agroconsultas::ConsultToChatEnabled', 1, { expires: date });
          })
          .catch((err) => {
            console.warn('Cannot check enabled chat', err);
            snackbars.showError('Ocurrio un error interno.');
          });
      }
    }

  };

  return { checkEnableChat, enableChat };
};
