import { LoginModal } from '@components/modal/Login/LoginModal';
import { Grid, Button } from '@mui/material';
import { useChatEnabled } from '@providers/hooks/ChatEnabledHook';
import { useSession } from '@providers/user/SessionContext';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import { items } from './items';
import styles from './Menu.styles';


const Menu: React.FC = () => {
  const classes = styles();
  const router = useRouter();
  const { isLogged, user } = useSession();
  const { enableChat, checkEnableChat } = useChatEnabled();
  const [loginOpen, setLoginOpen] = useState(false);
  const [cookies] = useCookies();

  const redirect = (url: string) => {
    url != '/chataco' ? router.push(url) : (isLogged ? router.push(url) : redirectToChat());
  };

  const redirectToChat = () => {
    router.push(
      {
        query: { destination: '/chataco' },
      },
      undefined,
      { shallow: true },
    );

    setLoginOpen(true);
  };

  useEffect(() => {
    checkEnableChat(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Grid container spacing={1} direction='row' justifyContent='flex-start' wrap='nowrap' alignItems='center'>
      {items
        .filter(item =>
          item.redirection !== '/chataco' ||
          (user && user.isAdmin) ||
          enableChat,
        )
        .map((item, index) => {
          const Icon = item.icon;

          return (
            <Grid item key={index}>
              <Button
                onClick={() => redirect(item.redirection)}
                disableRipple
                className={clsx(classes.itemRoot, { selected: item.selected(router.pathname) })}
                classes={{ text: classes.itemLabel }}>
                <Icon classes={{ root: classes.iconRoot }} />
                {item.text}
              </Button>
            </Grid>
          );
        })}

      {loginOpen && <LoginModal modalProps={{ open: loginOpen, onClose: () => setLoginOpen(false) }} />}
    </Grid>
  );
};

export default Menu;
