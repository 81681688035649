import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    smallButtonIcon: {
      color: '#FFFFFF',
      [StyleResponsive.onDesktop]: {
        border: '2px solid white',
        boxSizing: 'border-box',
        borderRadius: '6px',
        marginLeft: '2px',
        minWidth: 'min-content',
        minHeight: '40px',
        marginRight: '8px',

        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
          border: `2px solid ${theme.palette.primary.dark}`,
        },
      },
      [StyleResponsive.onMobile]: {
        fontSize: 25,
        marginRight: 0,
      },
    },

    buttons: {
      display: 'flex',
      alignItems: 'center',
    },

    loginButton: {
      backgroundColor: 'white',
      [StyleResponsive.onMobile]: {
        margin: 0,
      },
    },

    button: {
      maxHeight: '41px',
      padding: '0px 0px',
      '&:hover': {
        //backgroundColor: alpha(theme.palette.primary.dark, 0.3),
        borderRadius: '6px',
      },
    },

    avatar: {

      [StyleResponsive.onDesktop]: {
        marginRight: '8px',
      },

      [StyleResponsive.onMobile]: {
        fontSize: '1em',
        marginLeft: 5,
      },
    },

    icon: {
      color: '#FFFFFF',
      [StyleResponsive.onDesktop]: {
        marginRight: '4px',
      },
    },

    name: {
      letterSpacing: '0.1px',
      color: '#FFFFFF',
      textTransform: 'none',
      '&.login': {
        width: 'max-content',
        color: theme.palette.primary.main,
        '&:hover': {
          color: 'white',
        },
      },
    },

    profile: {
      display: 'flex',
      flexDirection: 'column',
    },

    profileMenuContainer: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',

      '& .MuiLink-root': {
        display: 'flex',
        color: theme.palette.secondary.main,
      },

      '& > .profileMenu': {
        marginTop: 10,
        width: 'max-content',
        position: 'absolute',
        zIndex: 1,
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 8,
        backgroundColor: 'white',
        overflow: 'hidden',
        right: 10,
        '& > .option': {
          '& svg': {
            marginRight: 10,
          },
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          color: theme.palette.secondary.main,
          fontSize: 16,
          padding: '14px 25px 14px 20px',
          '&:hover': {
            backgroundColor: 'rgba(34, 112, 168, 0.1);',
          },
        },
      },

      '& > .actionsMenu': {
        marginTop: 10,
        marginRight: 165,
        width: 'max-content',
        position: 'absolute',
        zIndex: 1,
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 8,
        backgroundColor: 'white',
        overflow: 'hidden',
        '& > .option': {
          '& svg': {
            marginRight: 10,
          },
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          color: theme.palette.secondary.main,
          fontSize: 16,
          padding: '14px 25px 14px 20px',
          '&:hover': {
            backgroundColor: 'rgba(34, 112, 168, 0.1);',
          },
        },
      },

      '& > .profileMobileMenu': {
        marginTop: 10,
        width: '100%',
        '& > .option': {
          '& svg': {
            marginRight: 10,
          },
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          color: theme.palette.secondary.main,
          fontSize: 16,
          padding: '14px 25px 14px 20px',
        },
      },

      '& > .profileNotifications': {
        marginTop: 10,
        width: '370px',
        height: '78vh',
        position: 'absolute',
        zIndex: 1,
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 8,
        backgroundColor: 'white',
        overflow: 'scroll',
        overflowX: 'hidden',
        marginRight: 320,
        color: theme.palette.text.primary,
        '& > .p': {
          '& svg': {
            marginRight: 10,
          },
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          color: theme.palette.secondary.main,
          fontSize: 16,
          padding: '14px 25px 14px 20px',
          '&:hover': {
            backgroundColor: 'rgba(34, 112, 168, 0.1);',
          },
        },
      },

      '& > .profileMore': {
        width: 172,
        marginTop: 4,
        position: 'absolute',
        zIndex: 1,
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 8,
        backgroundColor: 'white',
        marginRight: 148,
        color: theme.palette.text.primary,
        '& .MuiButton-root': {
          padding: 11,
        },
      },
    },

    profileMoreButton: {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 400,
      textTransform: 'none',
    },

    moreButton: {
      color: '#FFFFFF',
      fontSize: 25,
      marginRight: 0,
      minWidth: 0,
      padding: '0 2px',
    },

    modalProfile: {
      position: 'sticky',
      top: '55%',
      minHeight: '300px',
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      padding: '16px 19px',
      width: '100%',
      '& .MuiBox-root': {
        width: 'auto',
      },
    },


  }),
);
